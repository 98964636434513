import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { TransferSliceState } from './types';
import { requestTransferEthereum } from './asyncActions';


const initialState: TransferSliceState = {
    amountCrypto: null,
    amountUsd: null,
    addressTo: null,
    messageTransfer: '',
    statusTransfer: Status.CALM as Status,
}

const transferSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        setInfoTransfer(state, action) {
            const { amountCrypto, amountUsd, addressTo } = action.payload;
            state.addressTo = addressTo;
            state.amountUsd = amountUsd;
            state.amountCrypto = amountCrypto;
        },
        setDefault(state) {
            state.amountCrypto = null;
            state.amountUsd = null;
            state.addressTo = null;
            state.messageTransfer = '';
            state.statusTransfer = Status.CALM;
        }
    },
    extraReducers: (builder) => {
        // Transfer Ethereum
        builder.addCase(requestTransferEthereum.pending, (state) => {
            state.statusTransfer = Status.LOADING;
        })
        builder.addCase(requestTransferEthereum.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusTransfer = Status.SUCCESS;
                state.messageTransfer = 'Транзакция успешно создана';
            } else {
                state.statusTransfer = Status.ERROR;
                state.messageTransfer = 'Произошла ошибка при отправке ETH, попробуйте позже';
            }
        })
        builder.addCase(requestTransferEthereum.rejected, (state) => {
            state.statusTransfer = Status.ERROR;
            state.messageTransfer = 'Произошла ошибка при отправке ETH, попробуйте позже';
        })
    }
})

export const { setInfoTransfer, setDefault } = transferSlice.actions;

export default transferSlice.reducer;