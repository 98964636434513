import $api, { API_URL } from '../http/index';


export default class WalletService {
    static async getWalletEth() {
        const res = await $api.post(`${API_URL}/ethereum/wallet/ethereum`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getWalletBtc() {
        const res = await $api.post(`${API_URL}/bitcoin/wallet/bitcoin`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getQrCodeEth() {
        const res = await $api.get(`${API_URL}/ethereum/wallet/qr_code`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getQrCodeBtc() {
        const res = await $api.get(`${API_URL}/bitcoin/wallet/qr_code`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}