import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { TransferGasSliceState } from './types';
import { requestGetGasTransferEthereum } from './asyncActions';


const initialState: TransferGasSliceState = {
    statusGasTransfer: Status.CALM as Status,
    messageGasTransfer: '',
    gasTransfer: null,
}

const transferGasSlice = createSlice({
    name: 'transfer_gas',
    initialState,
    reducers: {
        setDefaultTransferGas(state) {
            state.statusGasTransfer = Status.CALM;
            state.messageGasTransfer = '';
            state.gasTransfer = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(requestGetGasTransferEthereum.pending, (state) => {
            state.statusGasTransfer = Status.LOADING;
        })
        builder.addCase(requestGetGasTransferEthereum.fulfilled, (state, action) => {
            if (action.payload) {
                state.gasTransfer = action.payload;
                state.statusGasTransfer = Status.SUCCESS;
            } else {
                state.statusGasTransfer = Status.ERROR;
                state.messageGasTransfer = 'Произошла ошибка при получении газа для отправки';
            }
        })
        builder.addCase(requestGetGasTransferEthereum.rejected, (state) => {
            state.statusGasTransfer = Status.ERROR;
            state.messageGasTransfer = 'Произошла ошибка при получении газа для отправки';
        })
    }
})

export const { setDefaultTransferGas } = transferGasSlice.actions;

export default transferGasSlice.reducer;