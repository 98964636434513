import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { WalletsSliceState } from './types';
import { requestGetWalletEthereum, requestGetWalletBitcoin } from './asyncActions';


const initialState: WalletsSliceState = {
    message: '',
    eth_wallet: null,
    btc_wallet: null,
    status: Status.CALM as Status,
}

const walletSlice = createSlice({
    name: 'wallets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Ethereum Wallet and Balance
        builder.addCase(requestGetWalletEthereum.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetWalletEthereum.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.eth_wallet = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении ETH кошелька';
            }
        })
        builder.addCase(requestGetWalletEthereum.rejected, (state) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при получении ETH кошелька';
        })
        // Get Bitcoin Wallet and Balance
        builder.addCase(requestGetWalletBitcoin.pending, (state) => {
            state.status = Status.LOADING;
        })
        builder.addCase(requestGetWalletBitcoin.fulfilled, (state, action) => {
            if (action.payload) {
                state.status = Status.SUCCESS;
                state.btc_wallet = action.payload;
            } else {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при получении BTC кошелька';
            }
        })
        builder.addCase(requestGetWalletBitcoin.rejected, (state) => {
            state.status = Status.ERROR;
            state.message = 'Произошла ошибка при получении BTC кошелька';
        })
    }
})

export default walletSlice.reducer;
