import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const Loading = () => {
    return (
        <Spin
            style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
            indicator={<LoadingOutlined style={{fontSize: 40, color: '#F73851'}} spin />}
        />
    )
}

export default Loading;