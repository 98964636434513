import { createAsyncThunk } from "@reduxjs/toolkit";

import WalletService from "../../services/WalletService";


export const requestGetQrCodeEthereum = createAsyncThunk('/ethereum/wallet/qr_code', async () => {
    const response = await WalletService.getQrCodeEth();
    return response;
})

export const requestGetQrCodeBitcoin = createAsyncThunk('/bitcoin/wallet/qr_code', async () => {
    const response = await WalletService.getQrCodeBtc();
    return response;
})
