import $api from "../http";


export default class ExchangeService {
    static async exchangeEthToUsd(value: number) {
        const res = await $api.get(`/ethereum/exchange/to_usd/${value}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async exchangeBtcToUsd(value: number) {
        const res = await $api.get(`/bitcoin/exchange/to_usd/${value}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}