import { createSlice } from '@reduxjs/toolkit';

import { Status } from '../../utils/utils';
import { QrCodesSliceState } from './types';
import { requestGetQrCodeEthereum, requestGetQrCodeBitcoin } from './asyncActions';


const initialState: QrCodesSliceState = {
    messageQrCode: '',
    eth_qr_code: null,
    btc_qr_code: null,
    statusQrCode: Status.CALM as Status,
}

const qrCodeSlice = createSlice({
    name: 'qrCodes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Get Ethereum QR Code
        builder.addCase(requestGetQrCodeEthereum.pending, (state) => {
            state.statusQrCode = Status.LOADING;
        })
        builder.addCase(requestGetQrCodeEthereum.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusQrCode = Status.SUCCESS;
                state.eth_qr_code = action.payload.qr_code;
            } else {
                state.statusQrCode = Status.ERROR;
                state.messageQrCode = 'Произошла ошибка при получении ETH QR Code, попробуйте позже';
            }
        })
        builder.addCase(requestGetQrCodeEthereum.rejected, (state) => {
            state.statusQrCode = Status.ERROR;
            state.messageQrCode = 'Произошла ошибка при получении ETH QR Code, попробуйте позже';
        })
        // Get Bitcoin QR Code
        builder.addCase(requestGetQrCodeBitcoin.pending, (state) => {
            state.statusQrCode = Status.LOADING;
        })
        builder.addCase(requestGetQrCodeBitcoin.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusQrCode = Status.SUCCESS;
                state.btc_qr_code = action.payload.qr_code;
            } else {
                state.statusQrCode = Status.ERROR;
                state.messageQrCode = 'Произошла ошибка при получении BTC QR Code, попробуйте позже';
            }
        })
        builder.addCase(requestGetQrCodeBitcoin.rejected, (state) => {
            state.statusQrCode = Status.ERROR;
            state.messageQrCode = 'Произошла ошибка при получении BTC QR Code, попробуйте позже';
        })
    }
})

export default qrCodeSlice.reducer;