import { createAsyncThunk } from "@reduxjs/toolkit";

import WalletService from "../../services/WalletService";


export const requestGetWalletEthereum = createAsyncThunk('/ethereum/wallet/ethereum', async () => {
    const response = await WalletService.getWalletEth();
    return response;
})

export const requestGetWalletBitcoin = createAsyncThunk('/bitcoin/wallet/bitcoin', async () => {
    const response = await WalletService.getWalletBtc();
    return response;
})