import $api from "../http";

import { formDataTransfer } from "../redux/transfer/types";
import { formDataGasPriceTransfer } from "../redux/transfer_gas/types";


export default class TransferService {
    static async transferEthereum(formData: formDataTransfer) {
        const res = await $api.post('/ethereum/transactions/send', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async transferBitcoin(formData: formDataTransfer) {
        const res = await $api.post('/bitcoin/transactions/send', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
    static async getGasTransferEthereum(formData: formDataGasPriceTransfer) {
        const res = await $api.post('/gas/get/eth/price', formData)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.response.data;
        })
        return res;
    }
}