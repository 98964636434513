import style from './sendCryptoCurrency.module.scss';

// React
import { useEffect, useState } from 'react';

// Disbound
import { useDebounce } from "use-debounce";

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Ant Design
import { message as antdMessage } from 'antd';

// Telegram
import { MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';

// Utils
import { currency, isValidBitcoinAddress, isValidEthereumAddress, Status } from '../../utils/utils';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectWallets } from '../../redux/wallets/selectors';
import { selectExchange } from '../../redux/exchange/selectors';
import { requestGetWalletBitcoin, requestGetWalletEthereum } from '../../redux/wallets/asyncActions';
import { requestExchangeBtcToUsd, requestExchangeEthToUsd } from '../../redux/exchange/asyncActions';
import { setInfoTransfer } from '../../redux/transfer/slice';
import { selectGasTransfer } from '../../redux/transfer_gas/selectors';
import { setDefaultTransferGas } from '../../redux/transfer_gas/slice';


const SendCryptoCurrency = () => {
    let { name } = useParams();
    const navigate = useNavigate();
    const filteredCurrency = currency.find(c => c.title.toLowerCase() === name?.toLowerCase());

    // Ant Design
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Address
    const [address, setAddress] = useState<string>('');
    const [debouncedValueAddress] = useDebounce(address, 1000);

    // Sum
    const [cryptoSum, setCryptoSum] = useState<string>('');
    const [debouncedValue] = useDebounce(cryptoSum, 1000);

    const handleInputChange = (event: any) => {
        const value = event.target.value;
        setCryptoSum(value);
    };

    // Get Wallets
    const dispatch = useAppDispatch();
    const { message, eth_wallet, btc_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (!eth_wallet && message.length <= 0) {
            dispatch(requestGetWalletEthereum());
        }
    }, [dispatch, eth_wallet, message])

    useEffect(() => {
        if (!btc_wallet && message.length <= 0) {
            dispatch(requestGetWalletBitcoin())
        }
    }, [dispatch, btc_wallet, message])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])

    // Error
    const errorExchangeSum = (
        filteredCurrency?.currency === 'ETH' && Number(eth_wallet?.balance_crypto) < Number(cryptoSum)
            ? {color: 'red'}
            : filteredCurrency?.currency === 'BTC' && Number(btc_wallet?.balance_crypto) < Number(cryptoSum)
                ? {color: 'red'}
                : {});

    // Exchange Sum to USD
    const { btc_to_usd, eth_to_usd, messageExchange } = useSelector(selectExchange);

    useEffect(() => {
        if (filteredCurrency?.currency === 'BTC' && !errorExchangeSum.color && typeof Number(debouncedValue) === 'number') {
            dispatch(requestExchangeBtcToUsd(Number(debouncedValue)))
        }
    }, [debouncedValue, dispatch])

    useEffect(() => {
        if (filteredCurrency?.currency === 'ETH' && !errorExchangeSum.color && typeof Number(debouncedValue) === 'number') {
            dispatch(requestExchangeEthToUsd(Number(debouncedValue)))
        }
    }, [debouncedValue, dispatch])

    useEffect(() => {
        if (messageExchange.length > 0) {
            messageApi.error(messageExchange, 2);
        }
    }, [messageExchange])

    const maxSumBtn = () => {
        if (filteredCurrency?.currency === 'BTC' && btc_wallet?.balance_crypto) {
            setCryptoSum(String(btc_wallet?.balance_crypto));
        }
        if (filteredCurrency?.currency === 'ETH' && eth_wallet?.balance_crypto) {
            setCryptoSum(String(eth_wallet?.balance_crypto));
        }
    }

    // Check Address
    const [isErrorAddress, setIsErrorAddress] = useState<boolean>(false);

    useEffect(() => {
        if (filteredCurrency?.currency === 'BTC') {
            const checkBtcAddress = isValidBitcoinAddress(debouncedValueAddress);
            setIsErrorAddress(!checkBtcAddress);
        }
    }, [debouncedValueAddress, isErrorAddress])

    useEffect(() => {
        if (filteredCurrency?.currency === 'ETH') {
            const checkEthAddress = isValidEthereumAddress(debouncedValueAddress);
            console.log('checkEthAddress: ', checkEthAddress);
            setIsErrorAddress(!checkEthAddress);
        }
    }, [debouncedValueAddress, isErrorAddress])

    // Redirect To Transfer
    const redirectToTransfer = () => {
        dispatch(setInfoTransfer({
            addressTo: address,
            amountUsd: filteredCurrency?.currency === 'BTC' ? btc_to_usd?.balance_usd : eth_to_usd?.balance_usd,
            amountCrypto: cryptoSum,
        }));

        navigate(`/home/transfer-crypto/${filteredCurrency?.title}`);
    }

    // Set Default Transfer Gas
    const { statusGasTransfer } = useSelector(selectGasTransfer);

    useEffect(() => {
        if (statusGasTransfer !== Status.CALM) {
            dispatch(setDefaultTransferGas())
        }
    }, [statusGasTransfer, dispatch])

    // // Telegram
    // const WebApp = useWebApp();

    // // Clickboard Text
    // const insertText = () => {
    //     WebApp.readTextFromClipboard((text: any) => {
    //         if (text) {
    //             setAddress(text);
    //         } else {
    //             messageApi.error('Буфер обмена пуст', 3000);
    //         }
    //     });
    // }

    return (
        <div className={style.send_crypto}>
            {contextHolder}
            <BackButton onClick={() => navigate(-1)} />
            <h1>Отправить {filteredCurrency?.currency}</h1>

            <div className={style.send_crypto__address}>
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder='Введите Адрес'
                />
                {/* <button onClick={insertText}>Вставить</button> */}
            </div>
            <div className={style.send_crypto__exchange_sum}>
                <p style={{color: 'red'}}>{debouncedValueAddress && isErrorAddress && 'Неверный адрес'}</p>
            </div>
            <div className={style.send_crypto__sum}>
                <input
                    type="number"
                    value={cryptoSum}
                    onChange={handleInputChange}
                    placeholder={`${filteredCurrency?.currency} Сумма`}
                />
                <button onClick={maxSumBtn}>Макс</button>
            </div>
            <div className={style.send_crypto__exchange_sum}>
                {errorExchangeSum.color ? <p style={errorExchangeSum}>Недостаточно средства</p> : <p>≈ {filteredCurrency?.currency === 'BTC' ? btc_to_usd?.balance_usd : eth_to_usd?.balance_usd}$</p>}
            </div>
            {cryptoSum && !errorExchangeSum.color && !isErrorAddress && (
                <MainButton text='Далее' color='#028e7f' onClick={redirectToTransfer} />
                // <button onClick={redirectToTransfer}>Далее</button>
            )}
        </div>
    )
}

export default SendCryptoCurrency;