import style from './Currency.module.scss';

// React
import { useEffect } from 'react';

// Route
import { Link, useNavigate, useParams } from 'react-router-dom';

// Icons
import gasIcon from '../../assets/img/gas.svg';

// Ant Design
import { message as antdMessage } from 'antd';
import { DownCircleFilled, FileSearchOutlined, UpCircleFilled } from '@ant-design/icons';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectWallets } from '../../redux/wallets/selectors';
import { requestGetWalletBitcoin, requestGetWalletEthereum } from '../../redux/wallets/asyncActions';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// WebSocket
import useWebSocket, { ReadyState } from 'react-use-websocket';

// Environments
import { API_SERVER_WEBSOCKET } from '../../http';

// Components
import Spinner from '../../components/utils/Spinner/Spinner';

// Utils
import { currency, Status } from '../../utils/utils';
import { selectRates } from '../../redux/rates/selectors';
import { setRates } from '../../redux/rates/slice';
import { selectGas } from '../../redux/gas/selectors';
import { setGas } from '../../redux/gas/slice';
import { selectTransfer } from '../../redux/transfer/selectors';
import { setDefault } from '../../redux/transfer/slice';


const Currency = () => {
    let { name } = useParams();
    const navigate = useNavigate();
    const filteredCurrency = currency.find(c => c.title.toLowerCase() === name?.toLowerCase());

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Wallet Ethereum
    const { message, eth_wallet, btc_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (filteredCurrency?.currency === 'BTC') {
            if (!btc_wallet && message.length <= 0) {
                dispatch(requestGetWalletBitcoin())
            }
        } else {
            if (!eth_wallet && message.length <= 0) {
                dispatch(requestGetWalletEthereum());
            }
        }
    }, [dispatch, filteredCurrency, btc_wallet, eth_wallet, message])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])

    // Get Rate Currency
    const { ethereumUsd, bitcoinUsd } = useSelector(selectRates);

    const token = localStorage.getItem('access_token');
    const WS_RATE_URL = `${API_SERVER_WEBSOCKET}/rate/ws`;
    const { lastJsonMessage: lastJsonMessageRate, readyState: readyStateRate } = useWebSocket(
        WS_RATE_URL,
        {
            share: false,
            reconnectInterval: 2000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyStateRate === ReadyState.OPEN) {
            if (lastJsonMessageRate && typeof lastJsonMessageRate === 'string') {
                const rates = JSON.parse(lastJsonMessageRate);
                dispatch(setRates({
                    ethereumUsd: rates['ethereum/usd']?.rate,
                    bitcoinUsd: rates['bitcoin/usd']?.rate,
                    ethereumRub: rates['ethereum/rub']?.rate,
                    bitcoinRub: rates['bitcoin/rub']?.rate,
                }));
            }
        }
    }, [readyStateRate, lastJsonMessageRate, dispatch])

    // Get Gas Fee
    const { bitcoinGas, ethereumGas } = useSelector(selectGas);

    const WS_GAS_URL = `${API_SERVER_WEBSOCKET}/gas/ws/${filteredCurrency?.currency === 'BTC' ? 'bitcoin' : 'ethereum'}`;
    const { lastJsonMessage: lastJsonMessageGas, readyState: readyStateGas } = useWebSocket(
        WS_GAS_URL,
        {
            share: false,
            reconnectInterval: 10000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyStateGas === ReadyState.OPEN) {
            if (lastJsonMessageGas && typeof lastJsonMessageGas === 'string') {
                const gas = JSON.parse(lastJsonMessageGas);
                dispatch(setGas({
                    gas: gas.gas,
                    type: filteredCurrency?.currency,
                }))
            }
        }
    }, [readyStateGas, lastJsonMessageGas, dispatch])

    // Transfer Notify
    const { statusTransfer, messageTransfer } = useSelector(selectTransfer);
    useEffect(() => {
        if (statusTransfer === Status.SUCCESS) {
            messageApi.success(messageTransfer, 2);
            dispatch(requestGetWalletBitcoin());
            dispatch(requestGetWalletEthereum());
        }

        return () => {
            dispatch(setDefault());
        }
    }, [statusTransfer, dispatch])

    return (
        <div className={style.currency_block}>
            <BackButton onClick={() => navigate('/home')} />
            {contextHolder}
            {!filteredCurrency ||
            filteredCurrency.currency === 'ETH' && !ethereumGas ||
            filteredCurrency.currency === 'BTC' && !bitcoinGas ||
            filteredCurrency.currency === 'BTC' && !btc_wallet ||
            filteredCurrency.currency === 'ETH' && !eth_wallet ||
            filteredCurrency.currency === 'BTC' && !bitcoinUsd ||
            filteredCurrency.currency === 'ETH' && !ethereumUsd
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.currency_block__info}>
                            <div className={style.currency_block__info__img}>
                                <img src={filteredCurrency.href} alt={filteredCurrency.title} />
                            </div>
                            <div className={style.currency_block__info__balance}>
                                <p>{filteredCurrency.currency === 'BTC' ? btc_wallet?.balance_crypto : eth_wallet?.balance_crypto} {filteredCurrency.currency}</p>
                            </div>
                            <div className={style.currency_block__info__rate}>
                                <p>${filteredCurrency.currency === 'BTC' ? btc_wallet?.balance_usd : eth_wallet?.balance_usd}</p>
                            </div>
                            <div className={style.currency_block__gas}>
                                <img src={gasIcon} alt="gas" />
                                <p>{filteredCurrency.currency === 'BTC' ? bitcoinGas : ethereumGas} $</p>
                            </div>
                        </div>
                        <div className={style.currency_block__actions}>
                            <Link
                                className={style.currency_block__actions__send}
                                to={`/home/send-crypto-currency/${filteredCurrency.title}`}
                            >
                                <UpCircleFilled />
                                <p>Отправить</p>
                            </Link>
                            <Link
                                className={style.currency_block__actions__receive}
                                to={`/home/currency/qr_code/${filteredCurrency.title}`}
                            >
                                <DownCircleFilled />
                                <p>Получить</p>
                            </Link>
                        </div>
                        <div className={style.line}></div>
                        <div className={style.currency_block__transactions}>
                            <div className={style.currency_block__transactions__not_found}>
                                <FileSearchOutlined />
                                <p>Здесь будут отображаться транзакции.</p>
                                <p>Не удается найти транзакцию?</p>
                                <a href={filteredCurrency.explorer}>Посмотреть обозреватель</a>
                            </div>
                        </div>
                        <div className={style.line}></div>
                        <div className={style.currency_block__rate}>
                            <p className={style.currency_block__rate__current}>Текущая цена {filteredCurrency.currency}</p>
                            <p>{filteredCurrency.currency === 'BTC'
                                ? bitcoinUsd
                                : ethereumUsd} $</p>
                        </div>
                    </>
                )}
        </div>
    )
}

export default Currency;