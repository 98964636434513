import style from './Home.module.scss';

// React
import { useEffect, useState } from 'react';

// Icons
import BitcoinIcon from '../../assets/img/crypto/bitcoin.svg';
import EthereumIcon from '../../assets/img/crypto/ethereum.svg';

// Ant Design
import { PlusCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { message as antdMessage } from 'antd';
import {
    Col,
    Row,
    Modal,
    Slider,
    InputNumber,
    InputNumberProps,
} from 'antd';

// Websocket
import useWebSocket, { ReadyState } from "react-use-websocket";

// Http
import { API_SERVER_WEBSOCKET } from '../../http/index';

// Router
import { Link } from 'react-router-dom';

// Components
import Currency from '../../components/home/Currency/Currency';
import Spinner from '../../components/utils/Spinner/Spinner';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setRates } from '../../redux/rates/slice';
import { selectRates } from '../../redux/rates/selectors';
import { selectWallets } from '../../redux/wallets/selectors';
import { requestGetWalletEthereum, requestGetWalletBitcoin } from '../../redux/wallets/asyncActions';


const Home = () => {
    const [inputValue, setInputValue] = useState<number>(3000);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const onChange: InputNumberProps['onChange'] = (newValue) => {
        setInputValue(newValue as number);
    };

    const { ethereumUsd, bitcoinUsd, ethereumRub, bitcoinRub } = useSelector(selectRates);

    // Redux
    const dispatch = useAppDispatch();

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Socket Get Rates
    const token = localStorage.getItem('access_token');
    const WS_URL = `${API_SERVER_WEBSOCKET}/rate/ws`;
    const { lastJsonMessage, readyState } = useWebSocket(
        WS_URL,
        {
            share: false,
            reconnectInterval: 2000,
            shouldReconnect: () => true,
            queryParams: {
                'token': token ? token.toString() : '',
            },
        },
    )

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            if (lastJsonMessage && typeof lastJsonMessage === 'string') {
                const rates = JSON.parse(lastJsonMessage);
                dispatch(setRates({
                    ethereumUsd: rates['ethereum/usd']?.rate,
                    bitcoinUsd: rates['bitcoin/usd']?.rate,
                    ethereumRub: rates['ethereum/rub']?.rate,
                    bitcoinRub: rates['bitcoin/rub']?.rate,
                }));
            }
        }
    }, [readyState, lastJsonMessage, dispatch])

    // Get Wallet Ethereum
    const { message, eth_wallet, btc_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (!eth_wallet && message.length <= 0) {
            dispatch(requestGetWalletEthereum());
        }
    }, [dispatch, eth_wallet, message])

    useEffect(() => {
        if (!btc_wallet && message.length <= 0) {
            dispatch(requestGetWalletBitcoin())
        }
    }, [dispatch, btc_wallet, message])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])

    return (
        <div className={style.home}>
            {contextHolder}
            <Modal
                onOk={handleOk}
                okText='Выбрать'
                open={isModalOpen}
                cancelText='Отмена'
                title="Выберите Сумму"
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={16}>
                        <Slider
                            min={3000}
                            step={1000}
                            max={30000}
                            defaultValue={3000}
                            onChange={onChange}
                            value={inputValue}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            min={3000}
                            step={1000}
                            max={30000}
                            value={inputValue}
                            onChange={onChange}
                            style={{ margin: '0 16px' }}
                        />
                    </Col>
                </Row>
            </Modal>
            <div className={style.home__balance}>
                <div className={style.home__balance_up}>
                    <div className={style.left}>
                        <PlusCircleFilled />
                        <p>Пополнить</p>
                    </div>
                    <div className={style.center}>
                        <h2>Кредитный лимит</h2>
                        <p>0 ₽</p>
                    </div>
                    <div className={style.right}>
                        <RightCircleFilled />
                        <p>Отправить</p>
                    </div>
                </div>
                <div className={style.home__balance_down}>
                    <button onClick={showModal}>Получить средства</button>
                </div>
            </div>
            {
            !ethereumUsd ||
            !bitcoinUsd ||
            !ethereumRub ||
            !bitcoinRub ||
            !eth_wallet ||
            !btc_wallet
                ? <Spinner />
                : (
                    <div className={style.home__currencies}>
                        <Link to={'/home/currency/ethereum'}>
                            <Currency
                                currencyBalance={eth_wallet.balance_usd}
                                currencyName='Ethereum'
                                currencyBalanceIcon='$'
                                currencyImage={EthereumIcon}
                                currencyRate={ethereumUsd}
                            />
                        </Link>
                        <Link to={'/home/currency/bitcoin'}>
                            <Currency
                                currencyBalance={btc_wallet.balance_usd}
                                currencyName='Bitcoin'
                                currencyBalanceIcon='$'
                                currencyImage={BitcoinIcon}
                                currencyRate={bitcoinUsd}
                            />
                        </Link>
                    </div>
                )}
        </div>
    )
}

export default Home;