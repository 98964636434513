import { createAsyncThunk } from "@reduxjs/toolkit";

import ExchangeService from "../../services/ExchangeService";


export const requestExchangeEthToUsd = createAsyncThunk('/ethereum/exchange/to_usd', async (value: number) => {
    const response = await ExchangeService.exchangeEthToUsd(value);
    return response;
})

export const requestExchangeBtcToUsd = createAsyncThunk('/bitcoin/exchange/to_usd', async (value: number) => {
    const response = await ExchangeService.exchangeBtcToUsd(value);
    return response;
})