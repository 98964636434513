import style from './qrCode.module.scss';

// React
import { useEffect } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectQrCodes } from '../../redux/qr_codes/selectors';

// Utils
import { selectWallets } from '../../redux/wallets/selectors';
import { requestGetWalletBitcoin, requestGetWalletEthereum } from '../../redux/wallets/asyncActions';
import { requestGetQrCodeBitcoin, requestGetQrCodeEthereum } from '../../redux/qr_codes/asyncActions';

// Components
import Spinner from '../../components/utils/Spinner/Spinner';

// Environments
import { API_URL } from '../../http';

// Utils
import { currency } from '../../utils/utils';


const QrCode = () => {
    let { name } = useParams();
    const navigate = useNavigate();
    const filteredCurrency = currency.find(c => c.title.toLowerCase() === name?.toLowerCase());

    // Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    
    // Redux
    const dispatch = useAppDispatch();

    // Get Wallet
    const { message, eth_wallet, btc_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (filteredCurrency?.currency === 'BTC') {
            if (!btc_wallet && message.length <= 0) {
                dispatch(requestGetWalletBitcoin())
            }
        } else {
            if (!eth_wallet && message.length <= 0) {
                dispatch(requestGetWalletEthereum());
            }
        }
    }, [dispatch, filteredCurrency, btc_wallet, eth_wallet, message])

    // Get Qr Codes
    const { btc_qr_code, eth_qr_code, messageQrCode } = useSelector(selectQrCodes);

    useEffect(() => {
        if (filteredCurrency?.currency === 'BTC') {
            if (!btc_qr_code && messageQrCode.length <= 0) {
                dispatch(requestGetQrCodeBitcoin());
            }
        } else {
            if (!eth_qr_code && messageQrCode.length <= 0) {
                dispatch(requestGetQrCodeEthereum());
            }
        }
    }, [dispatch, filteredCurrency, btc_qr_code, eth_qr_code])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])

    // Copy Wallet
    const clickBoardWallet = (e: any, wallet: any) => {
        e.preventDefault();
        try {
            // Creating a temporary text field
            const textArea = document.createElement('textarea');
            textArea.value = wallet;
            document.body.appendChild(textArea);

            // Selecting text in a field
            textArea.select();
            textArea.setSelectionRange(0, 99999); // Mobile Devices

            try {
                // Copy Text to clipboard
                const successful = document.execCommand('copy');
                const msg = successful ? 'Ссылка успешно скопирована' : 'Произошла ошибка при копировании ссылки';
                messageApi.info(msg, 2);
            } catch (err) {
                messageApi.error('Произошла ошибка при копировании ссылки', 2);
            }

            // Delete temporary text field
            document.body.removeChild(textArea);

        } catch (error) {
            messageApi.error('Произошла ошибка при копировании кошелька', 2);
        }
    }

    return (
        <div className={style.qr_code}>
            <BackButton onClick={() => navigate(-1)} />
            {contextHolder}
            {!filteredCurrency ||
             filteredCurrency.currency === 'BTC' && !btc_qr_code ||
             filteredCurrency.currency === 'ETH' && !eth_qr_code ||
             filteredCurrency.currency === 'BTC' && !btc_wallet ||
             filteredCurrency.currency === 'ETH' && !eth_wallet
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.qr_code__coin}>
                            <img src={filteredCurrency?.currency === 'BTC' ? currency[0].href : currency[1].href} alt="coin-img" />
                            <p>{filteredCurrency?.currency}</p>
                        </div>
                        <div className={style.qr_code__img}>
                            <img src={`${API_URL}${filteredCurrency?.currency === 'BTC' ? btc_qr_code : eth_qr_code}`} alt="qr-code-img" />
                        </div>
                        <div className={style.qr_code__wallet}>
                            <p>{filteredCurrency.currency === 'BTC' ? btc_wallet?.address : eth_wallet?.address}</p>
                        </div>
                        <div className={style.qr_code__actions}>
                            <button onClick={(e) => clickBoardWallet(e, filteredCurrency.currency === 'BTC' ? btc_wallet?.address : eth_wallet?.address)}>
                                <CopyOutlined />
                                <p>Копировать</p>
                            </button>
                        </div>
                    </>
                ) }
        </div>
    )
}

export default QrCode;