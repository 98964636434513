import style from './Transfer.module.scss';

// React
import { useEffect } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Telegram
import { MainButton, BackButton } from '@vkruglikov/react-telegram-web-app';

// Utils
import { currency, Status } from '../../utils/utils';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectWallets } from '../../redux/wallets/selectors';
import { formDataTransfer } from '../../redux/transfer/types';
import { selectTransfer } from '../../redux/transfer/selectors';
import { selectGasTransfer } from '../../redux/transfer_gas/selectors';
import { formDataGasPriceTransfer } from '../../redux/transfer_gas/types';
import { requestTransferEthereum } from '../../redux/transfer/asyncActions';
import { requestGetGasTransferEthereum } from '../../redux/transfer_gas/asyncActions';
import { requestGetWalletBitcoin, requestGetWalletEthereum } from '../../redux/wallets/asyncActions';


const Transfer = () => {
    let { name } = useParams();
    const navigate = useNavigate();
    const filteredCurrency = currency.find(c => c.title.toLowerCase() === name?.toLowerCase());

    // Ant Design
    const [messageApi, contextHolder] = antdMessage.useMessage();

    // Get Transfer
    const { statusTransfer, messageTransfer, addressTo, amountCrypto, amountUsd } = useSelector(selectTransfer);

    // Get Wallets
    const dispatch = useAppDispatch();
    const { message, eth_wallet, btc_wallet } = useSelector(selectWallets);

    useEffect(() => {
        if (!eth_wallet && message.length <= 0) {
            dispatch(requestGetWalletEthereum());
        }
    }, [dispatch, eth_wallet, message])

    useEffect(() => {
        if (!btc_wallet && message.length <= 0) {
            dispatch(requestGetWalletBitcoin())
        }
    }, [dispatch, btc_wallet, message])

    useEffect(() => {
        if (message.length > 0) {
            messageApi.error(message, 2);
        }
    }, [message])


    const transferAction = () => {
        if (addressTo && amountCrypto && gasTransfer) {
            const formData: formDataTransfer = {
                address_to: addressTo,
                amount: Number(amountCrypto) + gasTransfer?.gas_eth,
            }
            dispatch(requestTransferEthereum(formData));
        }
    }

    useEffect(() => {
        if (statusTransfer === Status.SUCCESS) {
            navigate(`/home/currency/${filteredCurrency?.title}`);    
        }
    }, [statusTransfer])

    useEffect(() => {
        if (statusTransfer === Status.ERROR && messageTransfer.length > 0) {
            messageApi.error(messageTransfer, 2);
        }
    }, [statusTransfer, messageTransfer])

    // Get Transfer Gas
    const { statusGasTransfer, messageGasTransfer, gasTransfer } = useSelector(selectGasTransfer);

    useEffect(() => {
        if (addressTo && amountCrypto && statusGasTransfer === Status.CALM && !gasTransfer) {
            const formDataGasTransfer: formDataGasPriceTransfer = {
                address_to: addressTo,
                amount: amountCrypto,
            }
            dispatch(requestGetGasTransferEthereum(formDataGasTransfer))
        }
    }, [statusGasTransfer, gasTransfer])

    useEffect(() => {
        if (statusGasTransfer === Status.ERROR && messageGasTransfer.length > 0) {
            messageApi.error(messageGasTransfer, 2);
        }
    }, [statusGasTransfer, messageGasTransfer])

    return (
        <div className={style.transfer}>
            {contextHolder}
            <BackButton onClick={() => navigate(-1)} />
            <h1>Перевод</h1>
            <div className={style.transfer__sum}>
                <p className={style.transfer__sum_coin}>- {amountCrypto} ETH</p>
                <p className={style.transfer__sum_usd}>{amountUsd} $</p>
            </div>
            <div className={style.transfer__info}>
                <div className={style.transfer__info__active}>
                    <p>Актив</p>
                    <p>{filteredCurrency?.title} ({filteredCurrency?.currency})</p>
                </div>
                <div className={style.transfer__info__wallet}>
                    <p>Кошелек</p>
                    <p>{filteredCurrency?.currency === 'BTC' ? btc_wallet?.address : eth_wallet?.address}</p>
                </div>
                <div className={style.transfer__info__to}>
                    <p>На</p>
                    <p>{addressTo}</p>
                </div>
                <div className={style.transfer__info__commission}>
                    <p>Комиссия сети</p>
                    <p>{gasTransfer?.gas_eth} ETH (≈{gasTransfer?.gas_usd} $)</p>
                </div>
                <div className={style.transfer__info__total}>
                    <p>Итого</p>
                    {amountUsd && gasTransfer 
                    ? (
                        <p>{parseFloat(String(amountUsd + gasTransfer?.gas_usd)).toFixed(2)} $</p>
                    )
                    : (
                        <p>{amountUsd} $</p>
                    )}
                </div>
            </div>
            <MainButton text='Подтвердить' color='#028e7f' onClick={transferAction} />
            {/* <button onClick={transferAction}>Подтвердить</button> */}
        </div>
    )
}

export default Transfer;