import style from './Welcome.module.scss';

// React
import { useEffect, useState } from 'react';

// Telegram
import { MainButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Navigate
import { useNavigate } from "react-router-dom";

// Images
import mainPhoto from '../../assets/img/welcome/main_photo.png';

// Redux
import { useAppDispatch } from '../../redux/store';
import { AuthFormData } from '../../redux/auth/types';
import { requestAuth } from '../../redux/auth/asyncActions';


const Welcome = () => {
    const WebApp = useWebApp();
    const user: WebAppUser = WebApp.initDataUnsafe.user;
    const [isClearLocalStorage, setIsClearLocalStorage] = useState<boolean>(false);

    // Redux
    const dispatch = useAppDispatch();

    // Get Token
    if (!isClearLocalStorage) {
        localStorage.clear();
        setIsClearLocalStorage(true);
    }
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            // Request Get Token
            // const formData: AuthFormData = {
            //     'telegram_id': user.id ? `${user.id}` : null,
            //     'first_name': user.first_name ? user.first_name : null,
            //     'last_name': user.last_name ? user.last_name : null,
            //     'username': user.username ? user.username : null,
            // }
            const formData: AuthFormData = {
                'telegram_id': '6566517473',
                'first_name': 'Gabe',
                'last_name': 'Dev',
                'username': 'dev5401',
            }
            dispatch(requestAuth(formData));
        }
    }, [token, dispatch, user])

    // Navigate to Home Page
    const navigate = useNavigate();

    const navigateTo = () => {
        navigate('/home');
    }

    return (
        <div className={style.welcome}>
            <div className={style.welcome__img_block}>
                <img src={mainPhoto} alt="main-photo" />
            </div>
            <div className={style.welcome__title}>
                <h1>Первый заём – бесплатно!</h1>
                <p>До 30 000 ₽, до 21 дня — без процентов*</p>
            </div>
            <MainButton text='Продолжить' color='#028e7f' onClick={navigateTo} />
        </div>
    )
}

export default Welcome;