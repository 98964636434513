// @ts-nocheck

import axios, { AxiosInstance } from 'axios';
// import { Profile } from '../redux/profile/types';


const webApp = window?.Telegram?.WebApp;

export const API_URL: string = `${process.env.REACT_APP_BACKEND_API}`;
export const API_SERVER_WEBSOCKET: string = `${process.env.REACT_APP_SERVER_WEBSOCKET}`;

const $api: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

$api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    }
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status == 401 &&
      !error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const token = localStorage.getItem('access_token');
        localStorage.removeItem('access_token');
        const response = await axios.get(`${API_URL}/auth/refresh?token=${token}`, {
          withCredentials: true,
        });
        localStorage.setItem('access_token', response.data.access_token);
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.access_token}`;
        return api.request(originalRequest);
      } catch (e) {
        console.log('Не авторизован. error in interceptors.response error callback -->', e);
      }
    }
    throw error;
  }
)

$api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    // if (error.response.status === 401 && !originalRequest._retry) {

    //   originalRequest._retry = true; // Пометка, что запрос уже пытался повториться
    //   try {
    //     // Запрос на получение нового токена
    //     const formData: Profile = {
    //       'telegram_id': webApp.initDataUnsafe.user.id ? `${webApp.initDataUnsafe.user.id}` : null,
    //       'first_name': webApp.initDataUnsafe.user.first_name ? webApp.initDataUnsafe.user.first_name : null,
    //       'last_name': webApp.initDataUnsafe.user.last_name ? webApp.initDataUnsafe.user.last_name : null,
    //       'username': webApp.initDataUnsafe.user.username ? webApp.initDataUnsafe.user.username : null,
    //     }

    //     const response = await axios.post(`${API_URL}/auth/authorization`, formData);
    //     const { token } = response.data;

    //     localStorage.setItem('token', token); // Обновление токена в хранилище
    //     $api.defaults.headers.common['Authorization'] = `${token}`; // Обновление токена в заголовках
    //     return $api(originalRequest); // Повторный запрос с обновленным токеном
    //   } catch (refreshError) {
    //     // Обработка ошибок при получении нового токена
    //     return Promise.reject(refreshError);
    //   }
    // }
    return error;
  }
)


export default $api;