import BitcoinIcon from '../assets/img/crypto/bitcoin.svg';
import EthereumIcon from '../assets/img/crypto/ethereum.svg';

export enum Status {
    CALM = 'calm',
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

type CurrencyType = {
    id: number;
    href: string;
    title: string;
    explorer: string;
    currency: string;
}

export const currency: CurrencyType[] = [
    {
        id: 1,
        currency: 'BTC',
        title: 'bitcoin',
        explorer: 'https://www.blockchain.com/explorer',
        href: BitcoinIcon,
    },
    {
        id: 2,
        currency: 'ETH',
        title: 'ethereum',
        explorer: 'https://etherscan.io/',
        href: EthereumIcon,
    },
]

export function isValidEthereumAddress(address: string) {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(address);
}

export function isValidBitcoinAddress(address: string) {
    const regex = /^(?:[13][a-km-zA-HJ-NP-Z1-9]{25,34}|bc1[ac-hj-np-zAC-HJ-NP-Z02-9]{11,71})$/;
    return regex.test(address);
}