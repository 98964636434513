import { createSlice } from '@reduxjs/toolkit';
import { RatesSliceState } from './types';


const initialState: RatesSliceState = {
    bitcoinUsd: null,
    bitcoinRub: null,
    ethereumUsd: null,
    ethereumRub: null,
}

const ratesSlice = createSlice({
    name: 'rates',
    initialState,
    reducers: {
        setRates(state, action) {
            const { ethereumUsd, bitcoinUsd, ethereumRub, bitcoinRub } = action.payload;
            state.ethereumUsd = ethereumUsd;
            state.bitcoinUsd = bitcoinUsd;
            state.ethereumRub = ethereumRub;
            state.bitcoinRub = bitcoinRub;
        },
    },
})

export const { setRates } = ratesSlice.actions;

export default ratesSlice.reducer;