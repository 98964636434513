import { createAsyncThunk } from "@reduxjs/toolkit";

import { formDataTransfer } from "./types";
import TransferService from "../../services/TransferService";


export const requestTransferEthereum = createAsyncThunk('/ethereum/transactions/send', async (formData: formDataTransfer) => {
    const response = await TransferService.transferEthereum(formData);
    return response;
})

export const requestTransferBitcoin = createAsyncThunk('/bitcoin/transactions/send', async (formData: formDataTransfer) => {
    const response = await TransferService.transferBitcoin(formData);
    return response;
})