import './assets/css/App.scss';

// Redux
import { ReduxProvider } from './redux/provider';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Home from './pages/home/Home';
import QrCode from './pages/qrCode/qrCode';
import Welcome from './pages/welcome/Welcome';
import Transfer from './pages/transfer/Transfer';
import Currency from './pages/currency/Currency';
import SendCryptoCurrency from './pages/sendCryptoCurrency/sendCryptoCurrency';

// Layouts
import { HomeLayout } from './layouts/layouts';


function App() {
  // Setup TG Color
  const WebApp = useWebApp();

  WebApp.headerColor = '#028e7f';

  // Full Screen TG
  if (!WebApp.isExpanded) {
    WebApp.expand();
  }

  // Enable Closing Confirm
  WebApp.enableClosingConfirmation();

  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path="/home" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path='currency/:name' element={<Currency />} />
            <Route path='currency/qr_code/:name' element={<QrCode />} />
            <Route path='send-crypto-currency/:name' element={<SendCryptoCurrency />} />
            <Route path='transfer-crypto/:name' element={<Transfer />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
