import style from './Currency.module.scss';


type Props = {
    currencyImage: string;
    currencyName: string;
    currencyRate: number | null;
    currencyBalance: number;
    currencyBalanceIcon: string;
}

const Currency = (
    { currencyImage, currencyName, currencyRate, currencyBalance , currencyBalanceIcon}: Props) => {
    return (
        <div className={style.currency}>
            <div className={style.currency__image}>
                <img src={currencyImage} alt="currency-image" />
            </div>
            <div className={style.currency_description}>
                <h3>{currencyName}</h3>
                <p>{currencyRate}</p>
            </div>
            <div className={style.currency__value}>
                <p>{currencyBalance} {currencyBalanceIcon}</p>
            </div>
        </div>
    )
}

export default Currency;