// Router
import { Outlet } from 'react-router-dom'


export function HomeLayout() {
    return (
        <>
            <Outlet />
        </>
    )
}