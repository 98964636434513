import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { requestAuth } from './asyncActions';
import { AuthSliceState } from './types';
import { Status } from '../../utils/utils';


const initialState: AuthSliceState = {
    message: '',
    status: Status.CALM as Status,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setStatus(state, action: PayloadAction<Status>) {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
           .addCase(requestAuth.pending, (state) => {
                state.status = Status.LOADING;
            })
           .addCase(requestAuth.fulfilled, (state, action) => {
                if (action.payload.tokens) {
                    state.status = Status.SUCCESS;
                    localStorage.setItem('access_token', action.payload.tokens.access_token);
                    localStorage.setItem('refresh_token', action.payload.tokens.refresh_token);
                } else {
                    state.status = Status.ERROR;
                    state.message = 'Произошла ошибка при авторизации';
                }
            })
           .addCase(requestAuth.rejected, (state, action) => {
                state.status = Status.ERROR;
                state.message = 'Произошла ошибка при авторизации';
            });
    },
})

export const { setMessage, setStatus } = authSlice.actions;

export default authSlice.reducer;