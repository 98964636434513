import { createSlice } from '@reduxjs/toolkit';
import { GasSliceState } from './types';


const initialState: GasSliceState = {
    bitcoinGas: null,
    ethereumGas: null,
}

const gasSlice = createSlice({
    name: 'gas',
    initialState,
    reducers: {
        setGas(state, action) {
            const { gas, type } = action.payload;
            if (type === 'ETH') {
                state.ethereumGas = gas;
            } else {
                state.bitcoinGas = gas;
            }
        }
    }
})

export const { setGas } = gasSlice.actions;

export default gasSlice.reducer;