import { createSlice } from "@reduxjs/toolkit";

import { Status } from "../../utils/utils";
import { ExchangeSliceState } from "./types";
import { requestExchangeBtcToUsd, requestExchangeEthToUsd } from "./asyncActions";



const initialState: ExchangeSliceState = {
    messageExchange: '',
    statusExchange: Status.CALM as Status,
    btc_to_usd: null,
    eth_to_usd: null,
}

const exchangeSlice = createSlice({
    name: 'exchange',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Exchange Ethereum to USD
        builder.addCase(requestExchangeEthToUsd.pending, (state) => {
            state.statusExchange = Status.LOADING;
        })
        builder.addCase(requestExchangeEthToUsd.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusExchange = Status.SUCCESS;
                state.eth_to_usd = action.payload;
            } else {
                state.statusExchange = Status.ERROR;
                state.messageExchange = 'Произошла ошибка при получении обменного курса ETH/USD';
            }
        })
        builder.addCase(requestExchangeEthToUsd.rejected, (state) => {
            state.statusExchange = Status.ERROR;
            state.messageExchange = 'Произошла ошибка при получении обменного курса ETH/USD';
        })

        // Exchange Bitcoin to USD
        builder.addCase(requestExchangeBtcToUsd.pending, (state) => {
            state.statusExchange = Status.LOADING;
        })
        builder.addCase(requestExchangeBtcToUsd.fulfilled, (state, action) => {
            if (action.payload) {
                state.statusExchange = Status.SUCCESS;
                state.btc_to_usd = action.payload;
            } else {
                state.statusExchange = Status.ERROR;
                state.messageExchange = 'Произошла ошибка при получении обменного курса BTC/USD';
            }
        })
        builder.addCase(requestExchangeBtcToUsd.rejected, (state) => {
            state.statusExchange = Status.ERROR;
            state.messageExchange = 'Произошла ошибка при получении обменного курса BTC/USD';
        })
    }
})

export default exchangeSlice.reducer;